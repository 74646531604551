import React, { useState } from "react";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { Color } from "./SearchLineItem";
import { useTranslation } from "react-i18next";

// MUI icons
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { DeleteSearch, DeleteSearchVariables } from "../../../__generated__/DeleteSearch";
import WarningModal from "../Dialog/WarningModal";
import { DeleteOpportunitySearch, DeleteOpportunitySearchVariables } from "../../../__generated__/DeleteOpportunitySearch";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import MatomoEvent from "../../../models/MatomoEvent";
import { Clear, CopyAll, Edit, Palette, SendTimeExtension, Share, StopCircle } from "@mui/icons-material";
import { DELETEOPPORTUNITYSEARCHRULE, DELETESEARCHRULE, UPDATE_SEARCH_PROPS } from "../../../graphql/mutationDefinitions";
import { GET_ORGANIZATION_SEARCHES, QUERY_SEARCHRULES } from "../../../graphql/queryDefCurrentUser";
import _ from "lodash";
import { updateTenderSearchProperties, updateTenderSearchPropertiesVariables } from "../../../__generated__/updateTenderSearchProperties";
import Colorpicker from "../../../components/Colorpicker";

interface Props {
    handleCloseSub: () => void;
    anchorEl: any;
    handleOpenPicker?: () => void;
    color?: string;
    setColor?: (color: string) => void;
    presetColors?: Color[];
    handleClosePicker?: () => void;
    openPicker?: boolean;
    handleOpenStats: () => void;
    openStats: boolean;
    handleCloseStats: () => void;
    searchRuleId: string;
    openEdit: () => void;
    setRemoveRuleLoader: (bool: boolean) => void;
    type: "tender" | "opportunity";
    openShare: () => void;
    openCopy: () => void;
    shareOpened: boolean;
    openMenu: boolean;
    openDistribution: () => void;
    chosenColors: string[];
    isOwner: boolean;
    openShareStop?: () => void;
    shareStopOpened?: boolean;
    searchline?: any;
}

const SearchLineMenu: React.FC<Props> = ({
    handleCloseSub,
    anchorEl,
    handleOpenPicker,
    setColor,
    presetColors,
    handleClosePicker,
    openPicker,
    handleOpenStats,
    openStats,
    handleCloseStats,
    searchRuleId,
    openEdit,
    type,
    setRemoveRuleLoader,
    openShare,
    openCopy,
    shareOpened,
    openMenu,
    openDistribution,
    color,
    chosenColors,
    isOwner,
    openShareStop,
    shareStopOpened,
    searchline,
}) => {
    const { t } = useTranslation();
    const [warning, setWarning] = useState(false);
    const [hover, sethover] = useState<{ type: string; hovered: string } | null>(null);
    const { trackEvent } = useMatomo();

    // Track event
    const trackevents = (event: MatomoEvent) => {
        trackEvent(event);
    };

    /**
     * Mutation to remove tendersearchrule
     */
    const [remove] = useMutation<DeleteSearch, DeleteSearchVariables>(DELETESEARCHRULE);

    /**
     * Mutation to edit color tendersearchrule
     */
    const [update_props] = useMutation<updateTenderSearchProperties, updateTenderSearchPropertiesVariables>(UPDATE_SEARCH_PROPS);

    /**
     * Mutation to remove opportunity searchrule
     */
    const [removeOppRule] = useMutation<DeleteOpportunitySearch, DeleteOpportunitySearchVariables>(DELETEOPPORTUNITYSEARCHRULE);
    /**
     * Check if storage has search to delete active as filteritem.
     * If id exist in array => remove from array to avoid errors in tableview
     * @param key key to check in storage
     */
    const removeSearchFromSessionStorage = (key: string) => {
        // Get the existing data
        const existing = sessionStorage.getItem(key);
        //  get parsed JSON or an empty array
        const data = existing ? JSON.parse(existing || "[]") : [];
        // if data.searches is empty
        if (_.isEmpty(data.searches)) {
            sessionStorage.setItem(key, JSON.stringify(data));
        } else {
            const searches = data.searches.filter((i: any) => i !== parseInt(searchRuleId));

            // Add new data to localStorage Array
            data["searches"] = searches;
            // Check length of searcharray
            if (data.searches.length === 0) {
                // remove property searches if it contains nothing
                delete data.searches;
            }

            // Save back to localStorage
            sessionStorage.setItem(key, JSON.stringify(data));
        }
    };

    const queryContainsAsteriskOrTilde =
        (type === "tender" && searchline.query.includes("*")) || (type === "tender" && searchline.query.includes("~"));

    /**
     * Handler to close the modal
     */
    const handleClose = () => {
        setWarning(false);

        if (type === "tender") {
            removeSearchFromSessionStorage("twf");
            removeSearchFromSessionStorage("rtf");
            onClickRemove();
            trackevents({ category: "Personal", action: "Click-remove-tender-searchrule", value: parseInt(searchRuleId) });
            setRemoveRuleLoader(true);
        }
        if (type === "opportunity") {
            removeSearchFromSessionStorage("wof");
            removeSearchFromSessionStorage("rof");
            onClickRemoveOpportunity();
            trackevents({ category: "Personal", action: "Click-remove-opportunity-searchrule", value: parseInt(searchRuleId) });
            setRemoveRuleLoader(true);
        }
    };

    const handleWarning = () => {
        setWarning(true);
    };

    /**
     * Handler to remove a opportunity searchrule
     */
    const onClickRemoveOpportunity = async () => {
        try {
            await removeOppRule({
                variables: {
                    id: searchRuleId,
                },
                refetchQueries: [
                    QUERY_SEARCHRULES, // DocumentNode object parsed with gql
                    "GetUserSearchRules", // Query name
                ],
                onCompleted: () => {
                    toast.success("Zoekregel verwijderd");
                },
            });
            setRemoveRuleLoader(true);
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        }
    };

    /**
     * Handler to remove a tender searchrule
     */
    const onClickRemove = async () => {
        try {
            await remove({
                variables: {
                    id: searchRuleId,
                },
                refetchQueries: [
                    QUERY_SEARCHRULES, // DocumentNode object parsed with gql
                    "GetUserSearchRules", // Query name
                    GET_ORGANIZATION_SEARCHES,
                    "GetOrganizationSearchRules",
                ],
                onCompleted: () => {
                    toast.success("Zoekregel verwijderd");
                },
            });
            setRemoveRuleLoader(true);
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        }
    };

    /**
     * Handler to update color
     */
    const onUpdateColor = async () => {
        try {
            await update_props({
                variables: {
                    id: searchRuleId,
                    color: color,
                },
                onCompleted: () => {
                    handleClosePicker && handleClosePicker();
                },
                refetchQueries: [
                    QUERY_SEARCHRULES, // DocumentNode object parsed with gql
                    "GetUserSearchRules", // Query name
                ],
            });
        } catch (e) {
            toast.error("Fout tijdens opslaan");
        }
    };

    return (
        // {/* Submenu to share, delete searchline */}
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={openMenu}
            onClose={handleCloseSub}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
        >
            {/* MenuItem to open stats*/}
            {/* <MenuItem onClick={openStats ? handleCloseStats : handleOpenStats}>
                <ListItemIcon>
                    <DonutLargeIcon />
                </ListItemIcon>
                <ListItemText primary={openStats ? "Verberg statistieken" : "Toon statistieken"} />
            </MenuItem> */}
            {/* MenuItem to edit searchline. */}

            <MenuItem
                disabled={queryContainsAsteriskOrTilde}
                onClick={openEdit}
                onMouseOver={() => {
                    sethover({ type, hovered: "edit" });
                }}
                onMouseOut={() => {
                    sethover(null);
                }}
            >
                <ListItemIcon>
                    <Edit
                        sx={{
                            color:
                                hover?.hovered === "edit" && type === "tender"
                                    ? "#465c84"
                                    : hover?.hovered === "edit" && type === "opportunity"
                                    ? "#508c79"
                                    : undefined,
                        }}
                    />
                </ListItemIcon>
                <ListItemText primary="Zoekregel aanpassen" />
            </MenuItem>

            {/* MenuItem to share searchline. */}
            {type === "tender" && (
                <MenuItem
                    onClick={() => {
                        openShare();
                    }}
                    onMouseOver={() => {
                        sethover({ type, hovered: "share" });
                    }}
                    onMouseOut={() => {
                        sethover(null);
                    }}
                >
                    <ListItemIcon>
                        <Share
                            sx={{
                                color: hover?.hovered === "share" && type === "tender" ? "#465c84" : undefined,
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText primary="Deel zoekregel" />
                </MenuItem>
            )}

            {/* MenuItem to share searchline. */}
            {type === "tender" && (
                <MenuItem
                    disabled={searchline.participants.length <= 1}
                    onClick={() => {
                        openShareStop && openShareStop();
                    }}
                    onMouseOver={() => {
                        sethover({ type, hovered: "stopshare" });
                    }}
                    onMouseOut={() => {
                        sethover(null);
                    }}
                >
                    <ListItemIcon>
                        <StopCircle
                            sx={{
                                color: hover?.hovered === "stopshare" && type === "tender" ? "#465c84" : undefined,
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText primary="Stop delen" />
                </MenuItem>
            )}

            <MenuItem
                onClick={() => {
                    openCopy();
                }}
                onMouseOver={() => {
                    sethover({ type, hovered: "copy" });
                }}
                onMouseOut={() => {
                    sethover(null);
                }}
            >
                <ListItemIcon>
                    <CopyAll
                        sx={{
                            color:
                                hover?.hovered === "copy" && type === "tender"
                                    ? "#465c84"
                                    : hover?.hovered === "copy" && type === "opportunity"
                                    ? "#508c79"
                                    : undefined,
                        }}
                    />
                </ListItemIcon>
                <ListItemText primary="Stuur kopie" />
            </MenuItem>
            {isOwner && type === "tender" && (
                <MenuItem
                    onClick={() => {
                        openDistribution();
                    }}
                    onMouseOver={() => {
                        sethover({ type, hovered: "delivery" });
                    }}
                    onMouseOut={() => {
                        sethover(null);
                    }}
                >
                    <ListItemIcon>
                        <SendTimeExtension
                            sx={{
                                color:
                                    hover?.hovered === "delivery" && type === "tender"
                                        ? "#465c84"
                                        : // : hover?.hovered === "delivery" && type === "opportunity"
                                          //   ? "#508c79"
                                          undefined,
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText primary="Wijzig bezorging" />
                </MenuItem>
            )}

            {/* MenuItem to edit color. */}
            {isOwner && type === "tender" && (
                <MenuItem
                    onClick={handleOpenPicker}
                    onMouseOver={() => {
                        sethover({ type, hovered: "color" });
                    }}
                    onMouseOut={() => {
                        sethover(null);
                    }}
                >
                    <ListItemIcon>
                        <Palette
                            sx={{
                                color: hover?.hovered === "color" ? color : undefined,
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText primary="Kleur aanpassen" />
                </MenuItem>
            )}
            {color && setColor && presetColors && handleClosePicker && openPicker && (
                <Colorpicker
                    chosenColorArray={chosenColors}
                    color={color}
                    onChange={setColor}
                    presetColors={presetColors}
                    onClosePicker={onUpdateColor}
                    open={openPicker}
                    cancelAndClose={handleClosePicker}
                />
            )}
            {/* MenuItem with delete function. */}

            <MenuItem
                onClick={() => {
                    handleWarning();
                    handleCloseSub();
                }}
                onMouseOver={() => {
                    sethover({ type, hovered: "clear" });
                }}
                onMouseOut={() => {
                    sethover(null);
                }}
            >
                <ListItemIcon>
                    <Clear
                        sx={{
                            color: hover?.hovered === "clear" ? (theme) => theme.palette.error.main : undefined,
                        }}
                    />
                </ListItemIcon>
                <ListItemText primary="Verwijder zoekregel" />
            </MenuItem>
            <WarningModal
                open={warning}
                onCancel={() => setWarning(false)}
                onExit={handleClose}
                helpText={"Weet je zeker dat je de zoekregel wilt verwijderen?"}
                closeText={t("UserAndTeams.Remove")}
                cancelText={t("Cancel")}
            />
        </Menu>
    );
};

export default SearchLineMenu;
