import { gql } from "@apollo/client";
// import * as fragments from "./fragments";

export const GET_SINGLE_POLL_TENDENZ = gql`
    query TendenZ_poll {
        TendenzPoll {
            id
            title
            questions {
                id
                text
                users_answered
                answers {
                    id
                    text
                    count
                }
            }
        }
    }
`;

export const SUBMIT_TENDENZ_POLL_ANSWER = gql`
    mutation submitAnswerTendenzQuestion($question_id: ID!, $answer_id: ID!, $poll_id: ID!) {
        submitAnswerTendenzQuestion(question_id: $question_id, answer_id: $answer_id, poll_id: $poll_id)
    }
`;

export const SUBMIT_TENDENZ_NEXT_QUESTION = gql`
    mutation tendenZNextQuestion($question_id: ID!, $poll_id: ID!) {
        tendenZNextQuestion(question_id: $question_id, poll_id: $poll_id)
    }
`;

export const TENDENZ_ACTIVE_QUESTION = gql`
    query tendenzActiveQuestion {
        activeTendenzQuestion {
            id
            text
            users_answered
            answers {
                id
                text
                count
            }
        }
    }
`;

export const FINISH_POLL = gql`
    mutation tendenZFinishPoll($poll_id: ID!) {
        tendenZFinishPoll(poll_id: $poll_id)
    }
`;
