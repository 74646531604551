import React, { useRef } from "react";
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import HeroDot1 from "../Hero_GUI/HeroDot1";
import OverlappingDotLeft from "../Hero_GUI/OverlappingDotLeft";
import OverlappingDotRight from "../Hero_GUI/OverlappingDotRight";
import WobbleLine from "../Hero_GUI/WobbleLine";
import FullPageSpinner from "../../../components/loading/FullPageSpinner";
import { usePoll } from "./PollContext";

interface Props {
    endOfPoll: boolean;
    admin?: boolean;
}

const Lobby: React.FC<Props> = ({ endOfPoll, admin }) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const tendenzRef = useRef<HTMLElement | null>(null);

    const { startPoll } = usePoll();

    return (
        // <PollLayout>
        <Box
            id="tendenz"
            ref={tendenzRef}
            py={4}
            sx={{
                height: noMobile ? "100vh" : `100vh`,
                display: "flex",
                flexDirection: "column",
                position: "relative",
                background: `linear-gradient(45deg, #70a4b7, #82b6a0)`,
                overflow: "hidden",
                width: "100vw",
            }}
        >
            <div style={{ flex: "1", position: "relative" }}>
                {/* LOGO */}
                {noMobile ? (
                    <Box sx={{ zIndex: 99, position: "relative", margin: noMobile ? "0px 32px 32px 16px" : "0px 32px 32px 0px" }}>
                        <img src="https://storage.googleapis.com/tenderguide-public/tendenzLogo.png" alt="TendenZ" width="auto" height="72px" />
                    </Box>
                ) : (
                    <Box
                        sx={{
                            position: "relative",
                            margin: "8px 32px 32px 8px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            zIndex: 99,
                        }}
                    >
                        <img src="https://storage.googleapis.com/tenderguide-public/tendenzLogo.png" alt="TendenZ" width="auto" height="56px" />
                    </Box>
                )}

                <Box
                    sx={{
                        position: "absolute",
                        bottom: noMobile ? "40%" : "30%",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "0",
                        flexDirection: "row",
                        width: "100%",
                        zIndex: 99,
                    }}
                >
                    {endOfPoll && !admin ? (
                        <div style={{ width: "95%" }}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12} textAlign="center" sx={{ marginBottom: "64px" }}>
                                    <Typography
                                        sx={{
                                            fontSize: "2.5rem !important", // Adjust font size as needed
                                            fontWeight: 700, // Adjust font weight as needed
                                            textAlign: "center", // Center align the text
                                        }}
                                    >
                                        Einde
                                    </Typography>
                                    <div style={{ height: "16px" }} />
                                    {/* Text */}
                                    <Typography
                                        sx={{
                                            fontSize: "1.5rem !important", // Adjust font size as needed
                                            fontWeight: 500, // Adjust font weight as needed
                                            textAlign: "center", // Center align the text
                                        }}
                                    >
                                        Bedankt
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    ) : admin ? (
                        <div style={{ width: "95%" }}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12} textAlign="center" sx={{ marginBottom: "64px" }}>
                                    <Typography
                                        sx={{
                                            fontSize: "2.5rem !important", // Adjust font size as needed
                                            fontWeight: 700, // Adjust font weight as needed
                                            textAlign: "center", // Center align the text
                                        }}
                                    >
                                        {endOfPoll ? "Einde" : "Welkom"}
                                    </Typography>
                                    <div style={{ height: "16px" }} />
                                    {/* Spinner */}

                                    {!endOfPoll && (
                                        <Button sx={{ zIndex: 99 }} onClick={startPoll} variant="contained" size="large">
                                            Starten
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                    ) : (
                        // Waiting screen no admin
                        <div style={{ width: "95%" }}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12} textAlign="center" sx={{ marginBottom: "64px" }}>
                                    <Typography
                                        sx={{
                                            fontSize: "2.5rem !important", // Adjust font size as needed
                                            fontWeight: 700, // Adjust font weight as needed
                                            textAlign: "center", // Center align the text
                                        }}
                                    >
                                        Welkom
                                    </Typography>
                                    <div style={{ height: "16px" }} />
                                    {/* Spinner */}
                                    <FullPageSpinner />
                                    {/* Text */}
                                    <Typography
                                        sx={{
                                            fontSize: "1.5rem !important", // Adjust font size as needed
                                            fontWeight: 500, // Adjust font weight as needed
                                            textAlign: "center", // Center align the text
                                        }}
                                    >
                                        Wachten op peiling
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    )}
                </Box>
            </div>

            {/* Dot left */}
            {<HeroDot1 />}
            {/* Dot  overlap left*/}
            {<OverlappingDotLeft top={noMobile ? undefined : "300px"} left={noMobile ? undefined : "150px"} />}
            {/* Dot */}
            {<OverlappingDotRight top={noMobile ? undefined : "550px"} left={noMobile ? undefined : "0px"} />}
            {/* Wobble line */}
            {noMobile && <WobbleLine />}
            {/* White shape behind logo */}
            <div
                className="custom-shape-divider-top-1720602832"
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: noMobile ? "400px" : "100%",
                    overflow: "hidden",
                    lineHeight: 0,
                    transform: `scaleX(-1)`,
                }}
            >
                <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                    style={{
                        position: "relative",
                        display: "block",
                        width: noMobile ? `calc(150% + 1.3px)` : `calc(130% + 1.3px)`,
                        height: noMobile ? "175px" : "160px",
                        transform: `rotateY(180deg)`,
                    }}
                >
                    <path d="M0,0V6c0,21.6,291,111.46,741,110.26,445.39,3.6,459-88.3,459-110.26V0Z" className="shape-fill" style={{ fill: "#fff" }} />
                </svg>
            </div>
        </Box>
        // </PollLayout>
    );
};

export default Lobby;
